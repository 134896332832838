import { Control, Coordinate, Position } from "../types";

import {
  calcAngleToCoordinate,
  calcDistanceToCoordinate,
  courseCorrectionHandler,
} from "../navigationMethods";
import { BOAT, NAVIGATION, navigationStates, SEARCH } from "../states";

const reachSearchPointHandler = (
  currentPosition: Coordinate,
  control: Control,
) => {
  //acceptable boundaries
  if (
    calcDistanceToCoordinate(currentPosition, SEARCH.TARGET) <=
    SEARCH.TARGET_REACHED_TOLERANCE
  ) {
    //* resets state , removes position from search path controls
    console.log("REACHED_POINT");
    BOAT.setBoatStraightAhead(control, BOAT.SPEED_MIN);
    SEARCH.setPathIndex(SEARCH.PATH_INDEX + 1);
    SEARCH.setTarget(null);
    NAVIGATION.setState(null);
  }
};
export const searchNavigationController = (
  control: Control,
  position: Position,
) => {
  let targetCourse: number;
  const currentHeading = position.getHeading();
  const currentPosition = position.getPosition();

  switch (NAVIGATION.STATE) {
    case navigationStates.CONFIG_SEARCH:
      console.log("CONFIG_SEARCH");
      //calc target angle, setCourse, setTarget & set NavigationSTATE to TURN
      SEARCH.setTarget(SEARCH.PATH[SEARCH.PATH_INDEX]);
      targetCourse = calcAngleToCoordinate(currentPosition, SEARCH.TARGET);

      NAVIGATION.setCourse(targetCourse);
      NAVIGATION.setState(navigationStates.TURN_SEARCH);
      break;

    case navigationStates.TURN_SEARCH:
      console.log("TURN_SEARCH");
      //turn to target angle within tolearance && setState to STRAIGHT
      console.log("SEARCH_COURSE", NAVIGATION.COURSE);
      console.log("CURRENT_HEADING", currentHeading);
      if (
        NAVIGATION.COURSE - NAVIGATION.COURSE_TOLERANCE < currentHeading &&
        currentHeading < NAVIGATION.COURSE + NAVIGATION.COURSE_TOLERANCE
      ) {
        BOAT.setBoatStraightAhead(control, BOAT.SPEED_HIGH);
        NAVIGATION.setState(navigationStates.STRAIGHT_POINT);
      } else {
        //TODO: add decision if left/right turn is faster
        BOAT.setBoatTurnOnSpotRight(control, BOAT.SPEED_HIGH);
      }
      break;

    case navigationStates.STRAIGHT_POINT:
      console.log("STRAIGHT_POINT");
      //recalc course && correct if necessary
      BOAT.setBoatStraightAhead(control, BOAT.SPEED_HIGH);
      targetCourse = calcAngleToCoordinate(currentPosition, SEARCH.TARGET);
      courseCorrectionHandler(currentHeading, targetCourse, control);
      reachSearchPointHandler(currentPosition, control);
      break;
  }
};
