import { Control, Coordinate } from "./types";

export enum navigationStates {
  CONFIG_SEARCH = "CONFIG_SEARCH",
  CONFIG_TRASH = "CONFIG_TRASH",
  CONFIG_BOUNDARY = "CONFIG_BOUNDARY",
  CONFIG_RANDOM = "CONFIG_RANDOM",
  TURN_SEARCH = "TURN_SEARCH",
  TURN_TRASH = "TURN_TRASH",
  TURN_BOUNDARY = "TURN_BOUNDARY",
  TURN_RANDOM = "TURN_RANDOM",
  STRAIGHT_POINT = "STRAIGHT_POINT",
  STRAIGHT_TRASH = "STRAIGHT_TRASH",
  STRAIGHT_BOUNDARY = "STRAIGHT_BOUNDARY",
  STRAIGHT_RANDOM = "STRAIGHT_RANDOM",
}

export namespace BOAT {
  export const SPEED_MIN = 0;
  export const SPEED_LOW = 0.4;
  export const SPEED_HIGH = 0.7;
  export const SPEED_MAX = 1;
  export let WEIGHT: number;
  export const setWeight = (newWeight: number) => {
    WEIGHT = newWeight;
  };
  export const setBoatStraightAhead = (c: Control, speed: number) => {
    c.setPowerLeft(speed);
    c.setPowerRight(speed);
  };
  export const setBoatTurnOnSpotLeft = (c: Control, speed: number) => {
    c.setPowerLeft(-speed);
    c.setPowerRight(speed);
  };
  export const setBoatTurnOnSpotRight = (c: Control, speed: number) => {
    c.setPowerLeft(speed);
    c.setPowerRight(-speed);
  };
  export const setBoatTurn = (
    c: Control,
    speedLeft: number,
    speedRight: number,
  ) => {
    c.setPowerLeft(speedLeft);
    c.setPowerRight(speedRight);
  };
}
export namespace NAVIGATION {
  export const STATE_HISTORY: navigationStates[] = [];
  export const addToHistory = (newState: navigationStates) =>
    STATE_HISTORY.push(newState);
  export let STATE: navigationStates | null = null;
  export const setState = (newNavigationState: navigationStates | null) => {
    STATE = newNavigationState;
    addToHistory(newNavigationState);
  };
  export let COURSE: number | null = null;
  export const setCourse = (newCourse: number) => {
    COURSE = newCourse;
  };
  export const COURSE_TOLERANCE = 5;
}

export namespace SEARCH {
  export let INIT: boolean = false;
  export const setInit = (value: boolean) => (INIT = value);
  export let PATH: Coordinate[] = [];
  export const addToPath = (newPoint: Coordinate) => PATH.push(newPoint);
  export const setPath = (newPoints: Coordinate[]) => (PATH = newPoints);
  export let PATH_INDEX = 0;
  export const setPathIndex = (newPathIndex: number) => {
    PATH_INDEX = newPathIndex;
  };
  export let TARGET: Coordinate | null = null;
  export const setTarget = (newTarget: Coordinate) => {
    TARGET = newTarget;
  };
  //in m
  export const TARGET_REACHED_TOLERANCE = 2.5;
}

export namespace POSITION {
  export const HISTORY: Coordinate[] = [];
  export const addToHistory = (newPoint: Coordinate) => HISTORY.push(newPoint);
}

export type Bounds = {
  minLat: number;
  maxLat: number;
  minLng: number;
  maxLng: number;
};
export namespace BOUNDARY {
  export let INIT = false;
  export const setInit = (init: boolean) => (INIT = init);

  export let POINTS: Coordinate[];
  export const setPoints = (coordinates: Coordinate[]) =>
    (POINTS = coordinates);

  export let AREA: Coordinate[];
  export const setArea = (newBounds: Coordinate[]) => (AREA = newBounds);

  export const AREA_TOLERANCE: number = 0.0002;
  export let BOUNDS: Bounds;
  export const setBounds = (newBounds: Bounds) => (BOUNDS = newBounds);

  export const INSIDE_CACHE: [boolean?] = [];
  export const addToInsideCache = (newCheck: boolean) =>
    INSIDE_CACHE.push(newCheck);
  export const INSIDE_CACHE_TOLERANCE = 20;

  export let TARGET: Coordinate;
  export const setTarget = (newTarget: Coordinate) => (TARGET = newTarget);
  export const TARGET_REACHED_TOLERANCE = 5;
}

export namespace RANDOM {
  export let IS_ENABLED = false;
  export const setIsEnabled = (status: boolean) => (IS_ENABLED = status);
  export let TARGET: Coordinate;
  export const setTarget = (newTarget: Coordinate) => (TARGET = newTarget);
  export const TARGET_REACHED_TOLERANCE = 5;
}
