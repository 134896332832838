import { Control, Coordinate, Position } from "../types";

import {
  calcAngleToCoordinate,
  calcDistanceToCoordinate,
  calcRandomCoordInsideBounds,
  courseCorrectionHandler,
} from "../navigationMethods";
import {
  BOAT,
  BOUNDARY,
  NAVIGATION,
  navigationStates,
  RANDOM,
  SEARCH,
} from "../states";

const reachTargetBoundaryHandler = (
  currentPosition: Coordinate,
  control: Control,
) => {
  //acceptable boundaries around random point inside search area
  if (
    calcDistanceToCoordinate(currentPosition, BOUNDARY.TARGET) <=
    BOUNDARY.TARGET_REACHED_TOLERANCE
  ) {
    //* resets state
    console.log("REACHED_TARGET_POINT_IN_BOUNDARY");
    BOAT.setBoatStraightAhead(control, BOAT.SPEED_MIN);
    BOUNDARY.setTarget(null);
    NAVIGATION.setState(null);
  }
};

export const boundaryNavigationController = (
  control: Control,
  position: Position,
) => {
  let targetCourse: number;
  let targetInsideBounds: Coordinate;
  const currentHeading = position.getHeading();
  const currentPosition = position.getPosition();

  switch (NAVIGATION.STATE) {
    case navigationStates.CONFIG_BOUNDARY:
      console.log("CONFIG_BOUNDARY");

      if (RANDOM.IS_ENABLED) {
        targetInsideBounds = calcRandomCoordInsideBounds(BOUNDARY.BOUNDS);
        console.log("BOUNDARY_TARGET_RANDOM", targetInsideBounds);
        targetCourse = calcAngleToCoordinate(
          currentPosition,
          targetInsideBounds,
        );
        console.log("BOUNDARY_COURSE_RANDOM", targetCourse);
      } else {
        targetInsideBounds = SEARCH.TARGET;
        console.log("BOUNDARY_TARGET_SEARCH", targetInsideBounds);
        targetCourse = calcAngleToCoordinate(
          currentPosition,
          targetInsideBounds,
        );
        console.log("BOUNDARY_COURSE_SEARCH", targetCourse);
      }

      //TODO: add different behaviour when !RANDOM.IS_RANDOM
      //! reachedTargetHandler needs to be modified as well if search point is a target

      NAVIGATION.setCourse(targetCourse);
      BOUNDARY.setTarget(targetInsideBounds);
      NAVIGATION.setState(navigationStates.TURN_BOUNDARY);
      break;

    case navigationStates.TURN_BOUNDARY:
      console.log("TURN_BOUNDARY");
      //turn to target angle within tolearance && setState to STRAIGHT
      console.log("BOUNDARY_COURSE", NAVIGATION.COURSE);
      console.log("CURRENT_HEADING", currentHeading);
      if (
        NAVIGATION.COURSE - NAVIGATION.COURSE_TOLERANCE < currentHeading &&
        currentHeading < NAVIGATION.COURSE + NAVIGATION.COURSE_TOLERANCE
      ) {
        BOAT.setBoatStraightAhead(control, BOAT.SPEED_HIGH);
        NAVIGATION.setState(navigationStates.STRAIGHT_BOUNDARY);
      } else {
        //TODO: add decision if left/right turn is faster
        BOAT.setBoatTurnOnSpotRight(control, BOAT.SPEED_HIGH);
      }
      break;

    case navigationStates.STRAIGHT_BOUNDARY:
      console.log("STRAIGHT_BOUNDARY");
      //recalc course && correct if necessary
      BOAT.setBoatStraightAhead(control, BOAT.SPEED_LOW);
      targetCourse = calcAngleToCoordinate(currentPosition, BOUNDARY.TARGET);
      courseCorrectionHandler(currentHeading, targetCourse, control);
      reachTargetBoundaryHandler(currentPosition, control);
      break;

    default:
      break;
  }
};
