import { Control, Coordinate, Position } from "../types";

import {
  calcAngleToCoordinate,
  calcDistanceToCoordinate,
  calcRandomCoordInsideBounds,
  courseCorrectionHandler,
} from "../navigationMethods";
import {
  BOAT,
  BOUNDARY,
  NAVIGATION,
  navigationStates,
  RANDOM,
} from "../states";

const reachRandomTargetHandler = (
  currentPosition: Coordinate,
  control: Control,
) => {
  //acceptable boundaries around random point inside search area
  if (
    calcDistanceToCoordinate(currentPosition, RANDOM.TARGET) <=
    RANDOM.TARGET_REACHED_TOLERANCE
  ) {
    //* resets state
    console.log("REACHED_RANDOM_POINT");
    BOAT.setBoatStraightAhead(control, BOAT.SPEED_MIN);
    RANDOM.setTarget(null);
    NAVIGATION.setState(null);
  }
};

export const randomNavigationController = (
  control: Control,
  position: Position,
) => {
  let targetCourse: number;
  const currentHeading = position.getHeading();
  const currentPosition = position.getPosition();

  switch (NAVIGATION.STATE) {
    case navigationStates.CONFIG_RANDOM:
      console.log("CONFIG_RANDOM");
      const targetInsideBounds = calcRandomCoordInsideBounds(BOUNDARY.BOUNDS);
      console.log("CONFIG_RANDOM_TARGET", targetInsideBounds);
      targetCourse = calcAngleToCoordinate(currentPosition, targetInsideBounds);
      console.log("CONFIG_RANDOM_TARGET_COURSE", targetCourse);

      NAVIGATION.setCourse(targetCourse);
      RANDOM.setTarget(targetInsideBounds);
      NAVIGATION.setState(navigationStates.TURN_RANDOM);
      break;

    case navigationStates.TURN_RANDOM:
      console.log("TURN_RANDOM");
      //turn to target angle within tolearance && setState to STRAIGHT
      console.log("RANDOM_COURSE", NAVIGATION.COURSE);
      console.log("CURRENT_HEADING", currentHeading);

      if (
        NAVIGATION.COURSE - NAVIGATION.COURSE_TOLERANCE < currentHeading &&
        currentHeading < NAVIGATION.COURSE + NAVIGATION.COURSE_TOLERANCE
      ) {
        BOAT.setBoatStraightAhead(control, BOAT.SPEED_HIGH);
        NAVIGATION.setState(navigationStates.STRAIGHT_RANDOM);
      } else {
        //TODO: add decision if left/right turn is faster
        BOAT.setBoatTurnOnSpotRight(control, BOAT.SPEED_HIGH);
      }
      break;

    case navigationStates.STRAIGHT_RANDOM:
      console.log("STRAIGHT_RANDOM");
      //recalc course && correct if necessary
      BOAT.setBoatStraightAhead(control, BOAT.SPEED_LOW);
      targetCourse = calcAngleToCoordinate(currentPosition, RANDOM.TARGET);
      courseCorrectionHandler(currentHeading, targetCourse, control);
      reachRandomTargetHandler(currentPosition, control);
      break;

    default:
      break;
  }
};
