import { Control, Position, Trash } from "../types";

import {
  calcTrashAngle,
  courseCorrectionHandler,
  determineTrashTurnDirection,
  turnDirections,
} from "../navigationMethods";
import { BOAT, NAVIGATION, navigationStates } from "../states";
import { trashDetectionChecker } from "../utilities";

export const trashNavigationController = ({
  control,
  position,
  trashGroup,
}: {
  control: Control;
  position: Position;
  trashGroup?: Trash[];
}) => {
  let targetCourse: number;
  let trash: false | Trash;
  const currentHeading = position.getHeading();

  switch (NAVIGATION.STATE) {
    case navigationStates.CONFIG_TRASH:
      console.log("CONFIG_TRASH");
      //config && slow down
      BOAT.setBoatStraightAhead(control, BOAT.SPEED_MIN);
      trash = trashDetectionChecker(trashGroup);
      if (trash) {
        NAVIGATION.setState(navigationStates.TURN_TRASH);
      } else {
        NAVIGATION.setState(navigationStates.CONFIG_SEARCH);
      }
      break;

    case navigationStates.TURN_TRASH:
      //turn to target angle within tolearance && setState to STRAIGHT
      console.log("TURN_TRASH");
      console.log("TRASH_COURSE", NAVIGATION.COURSE);
      console.log("CURRENT_HEADING", currentHeading);

      //reset if already collected by chance
      trash = trashDetectionChecker(trashGroup);

      if (trash) {
        switch (
          determineTrashTurnDirection(
            trash.direction,
            currentHeading,
            NAVIGATION.COURSE_TOLERANCE,
          )
        ) {
          case turnDirections.STRAIGHT:
            NAVIGATION.setState(navigationStates.STRAIGHT_TRASH);
            break;

          case turnDirections.LEFT:
            BOAT.setBoatTurnOnSpotLeft(control, BOAT.SPEED_HIGH);
            break;
          case turnDirections.RIGHT:
            BOAT.setBoatTurnOnSpotRight(control, BOAT.SPEED_HIGH);
        }
      } else {
        //!BUG: it can happen that it keeps switching between TURN_TRASH && TURN_SEARCH, MAYBE CACHE VALUE?
        NAVIGATION.setState(null);
      }

      break;

    case navigationStates.STRAIGHT_TRASH:
      console.log("STRAIGHT_TRASH");
      //recalc course && correct if necessary
      BOAT.setBoatStraightAhead(control, BOAT.SPEED_HIGH);
      trash = trashDetectionChecker(trashGroup);
      if (trash) {
        targetCourse = calcTrashAngle(trash.direction, currentHeading);
        courseCorrectionHandler(currentHeading, targetCourse, control);
      } else {
        NAVIGATION.setState(null);
      }
      break;

    default:
      break;
  }
};
