//ADD ALL ANGLE/DIRECTION RELATED CODE HERE
import {Control, Coordinate, Trash} from "./types";

import {BOAT} from "./states";

export function calcAngleToCoordinate(
  currentPosition: Coordinate,
  targetPoint: Coordinate,
): number {
  const {longitude: boatLong, latitude: boatLat} = currentPosition;
  const {longitude: targetLong, latitude: targetLat} = targetPoint;

  //construct triangle to calc angle to target
  const opposite = Math.abs(targetLat - boatLat);
  const adjacent = Math.abs(targetLong - boatLong);
  const hypotenuse = Math.hypot(opposite, adjacent);

  //calc absolute angle to target with north = 0 Deg
  const cosOfAngleX = opposite / hypotenuse;
  const angleToTarget = (Math.acos(cosOfAngleX) * 180) / Math.PI;

  //return absolute angle
  if (targetLong - boatLong > 0) {
    if (targetLat - boatLat > 0) {
      return 180 - angleToTarget;
    }
    if (targetLat - boatLat < 0) {
      return angleToTarget;
    }
  }
  if (targetLong - boatLong < 0) {
    if (targetLat - boatLat > 0) {
      return 180 + angleToTarget;
    }
    if (targetLat - boatLat < 0) {
      return 360 - angleToTarget;
    }
  }
  if (targetLong - boatLong === 0) {
    if (targetLat - boatLat > 0) {
      return 180;
    }
    if (targetLat - boatLat < 0) {
      return 0;
    }
    if (targetLat === boatLat) {
      return undefined;
    }
  }
  return undefined;
}

export function calcTrashAngle(
  direction: Trash["direction"],
  currentHeading: number,
) {
  const angleSum = currentHeading + direction;
  return (angleSum + 360) % 360;
}

export enum turnDirections {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
  STRAIGHT = "STRAIGHT",
}

export function determineTrashTurnDirection(
  relativeaAngle: number,
  currentHeading: number,
  courseTolerance: number,
): turnDirections {
  const absoluteAngle = calcTrashAngle(relativeaAngle, currentHeading);
  if (
    absoluteAngle - courseTolerance < currentHeading &&
    currentHeading < absoluteAngle + courseTolerance
  ) {
    return turnDirections.STRAIGHT;
  }
  if (relativeaAngle < 0) {
    return turnDirections.LEFT;
  }
  if (relativeaAngle > 0) {
    return turnDirections.RIGHT;
  }
}

//uses Haversine formula, earth is a spheroid and not a sphere, but should be close enough for our use case
//returns distance in km

export enum distanceUnit {
  m = "meter",
  k = "kilometer",
}
export function calcDistanceToCoordinate(
  originCoord: Coordinate,
  targetCoord: Coordinate,
  unit?: distanceUnit,
): number {
  var R = 6371; // Radius of the earth in km
  var dLat = convertDegToRad(targetCoord.latitude - originCoord.latitude);
  var dLong = convertDegToRad(targetCoord.longitude - originCoord.longitude);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(convertDegToRad(originCoord.latitude)) *
      Math.cos(convertDegToRad(targetCoord.latitude)) *
      Math.sin(dLong / 2) *
      Math.sin(dLong / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  if (unit === distanceUnit.k) return d;
  return d * 1000;
}

function convertDegToRad(deg) {
  return deg * (Math.PI / 180);
}

export function courseCorrectionHandler(
  currentHeading: number,
  targetCourse: number,
  control: Control,
) {
  if (currentHeading > targetCourse) {
    BOAT.setBoatTurn(control, BOAT.SPEED_HIGH, BOAT.SPEED_MAX);
  } else if (currentHeading < targetCourse) {
    BOAT.setBoatTurn(control, BOAT.SPEED_MAX, BOAT.SPEED_HIGH);
  }
}

export type Bounds = {
  minLat: number;
  maxLat: number;
  minLng: number;
  maxLng: number;
};

export function calcRandomCoordInsideBounds(bounds: Bounds): Coordinate {
  const {minLat, maxLat, minLng, maxLng} = bounds;
  const latitude = Math.random() * (maxLat - minLat) + minLat;
  const longitude = Math.random() * (maxLng - minLng) + minLng;
  return {latitude, longitude};
}
