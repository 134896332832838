import {Coordinate} from "sea-drone-simulation";

export const testCoordinates: Coordinate[] = [
  //test data for muggelsee
  {longitude: 52.441604, latitude: 13.627809},
  {longitude: 52.440854, latitude: 13.627809},
  {longitude: 52.441604, latitude: 13.627609},
  {longitude: 52.440854, latitude: 13.627609},
  {longitude: 52.441604, latitude: 13.627309},
  {longitude: 52.440854, latitude: 13.627309},
];
