import { getBounds, isPointInPolygon } from "geolib";
import { takeRight, countBy } from "lodash";
import { Control, Position, Coordinate, Trash, UserCommands } from "./types";

import {
  boundaryNavigationController,
  randomNavigationController,
  searchNavigationController,
  trashNavigationController,
} from "./navigationController/index";

import {
  BOUNDARY,
  NAVIGATION,
  navigationStates,
  POSITION,
  RANDOM,
  SEARCH,
  BOAT,
} from "./states";
import { trashDetectionChecker } from "./utilities";

const initSearchPath = (coordinates: Coordinate[]) => {
  //init coordinates
  if (!SEARCH.INIT) {
    coordinates ? SEARCH.setPath(coordinates) : SEARCH.setPath([]);
    SEARCH.setInit(true);
  }
};

const initRandomBehavior = (value: boolean | undefined) => {
  if (!value) return;
  RANDOM.setIsEnabled(value);
};
const boundaryHandler = (position: Position) => {
  //setup
  if (!BOUNDARY.INIT) {
    BOUNDARY.setPoints(SEARCH.PATH);
    //calc max boundary points and transform them to be used as polygon
    BOUNDARY.setBounds(getBounds(BOUNDARY.POINTS));
    //set bounds as polygon, add additional fault tolerance
    BOUNDARY.setArea([
      {
        latitude: BOUNDARY.BOUNDS.minLat - BOUNDARY.AREA_TOLERANCE,
        longitude: BOUNDARY.BOUNDS.minLng - BOUNDARY.AREA_TOLERANCE,
      },
      {
        latitude: BOUNDARY.BOUNDS.minLat - BOUNDARY.AREA_TOLERANCE,
        longitude: BOUNDARY.BOUNDS.maxLng + BOUNDARY.AREA_TOLERANCE,
      },
      {
        latitude: BOUNDARY.BOUNDS.maxLat + BOUNDARY.AREA_TOLERANCE,
        longitude: BOUNDARY.BOUNDS.maxLng + BOUNDARY.AREA_TOLERANCE,
      },
      {
        latitude: BOUNDARY.BOUNDS.maxLat + BOUNDARY.AREA_TOLERANCE,
        longitude: BOUNDARY.BOUNDS.minLng - BOUNDARY.AREA_TOLERANCE,
      },
    ]);
    BOUNDARY.setInit(true);
  }
  //check if boat is inside polygon of points
  BOUNDARY.addToInsideCache(
    isPointInPolygon(position.getPosition(), BOUNDARY.AREA),
  );

  if (
    BOUNDARY.INSIDE_CACHE.length >= BOUNDARY.INSIDE_CACHE_TOLERANCE &&
    NAVIGATION.STATE !== navigationStates.CONFIG_BOUNDARY &&
    NAVIGATION.STATE !== navigationStates.TURN_BOUNDARY &&
    NAVIGATION.STATE !== navigationStates.STRAIGHT_BOUNDARY
  ) {
    //take last values according to tolerance
    const lastCacheValues = takeRight(
      BOUNDARY.INSIDE_CACHE,
      BOUNDARY.INSIDE_CACHE_TOLERANCE,
    );
    //sorts && counts up cachevalues by true/false
    const countedCacheValues = countBy(lastCacheValues);

    if (countedCacheValues.false >= BOUNDARY.INSIDE_CACHE_TOLERANCE / 2) {
      console.log("EXITED_SEARCH_BOUNDARY");
      NAVIGATION.setState(navigationStates.CONFIG_BOUNDARY);
    }
  }
};

const detectedTrashHandler = (trashGroup: Trash[]) => {
  if (
    NAVIGATION.STATE !== navigationStates.CONFIG_TRASH &&
    NAVIGATION.STATE !== navigationStates.TURN_TRASH &&
    NAVIGATION.STATE !== navigationStates.STRAIGHT_TRASH &&
    NAVIGATION.STATE !== navigationStates.CONFIG_BOUNDARY &&
    NAVIGATION.STATE !== navigationStates.TURN_BOUNDARY &&
    NAVIGATION.STATE !== navigationStates.STRAIGHT_BOUNDARY
  ) {
    //run trash detection constantly
    const trash = trashDetectionChecker(trashGroup);
    if (trash) {
      //TODO: account for trash proximity
      NAVIGATION.setState(navigationStates.CONFIG_TRASH);
    }
  }
};
const randomDirectionHandler = () => {
  if (!NAVIGATION.STATE && RANDOM.IS_ENABLED) {
    NAVIGATION.setState(navigationStates.CONFIG_RANDOM);
  }
};

const searchPathHandler = () => {
  if (
    !NAVIGATION.STATE &&
    SEARCH.PATH.length > 0 &&
    SEARCH.PATH_INDEX < SEARCH.PATH.length
  ) {
    NAVIGATION.setState(navigationStates.CONFIG_SEARCH);
  }
  if (SEARCH.PATH_INDEX > SEARCH.PATH.length - 1) {
    console.log("COMPLETED_SEARCH/SEARCH_RESET");
    SEARCH.setPathIndex(0);
  }
};

//core navigationhandler that runs every loop
export const navigationHandler = ({
  control,
  position,
  coordinates,
  command,
  trash,
  enableRandom,
}: {
  control: Control;
  position: Position;
  command: UserCommands;
  coordinates: Coordinate[];
  trash?: Trash[];
  enableRandom?: boolean;
}) => {
  //command = "STOP";
  //!user commands take precedence over everything

  switch (command) {
    case "START":
      //init search path
      initSearchPath(coordinates);
      initRandomBehavior(enableRandom);
      //save current position in history
      POSITION.addToHistory(position.getPosition());
      //handler which determine what controller to execute
      boundaryHandler(position);
      detectedTrashHandler(trash);
      randomDirectionHandler();
      searchPathHandler();
      break;
    case "STOP":
      POSITION.addToHistory(position.getPosition());
      control.setPowerLeft(BOAT.SPEED_MIN);
      control.setPowerRight(BOAT.SPEED_MIN);
      break;
    case "RETURN":
      initSearchPath([coordinates[0]]);
      POSITION.addToHistory(position.getPosition());
      boundaryHandler(position);
      searchPathHandler();
      break;
    case "TEST_FORWARD":
      control.setPowerLeft(BOAT.SPEED_HIGH);
      control.setPowerRight(BOAT.SPEED_HIGH);
      break;
    case "TEST_LEFT":
      control.setPowerLeft(BOAT.SPEED_HIGH);
      control.setPowerRight(BOAT.SPEED_MIN);
      break;
    case "TEST_RIGHT":
      control.setPowerLeft(BOAT.SPEED_MIN);
      control.setPowerRight(BOAT.SPEED_HIGH);
      break;
    case "TEST_TURN_LEFT":
      control.setPowerLeft(-BOAT.SPEED_HIGH);
      control.setPowerRight(BOAT.SPEED_HIGH);
      console.log("HEADING", position.getHeading());
    case "TEST_TURN_RIGHT":
      control.setPowerLeft(BOAT.SPEED_HIGH);
      control.setPowerRight(-BOAT.SPEED_HIGH);
      console.log("HEADING", position.getHeading());
    default:
  }

  //contoller which handle the driving part
  boundaryNavigationController(control, position);
  trashNavigationController({ control, position, trashGroup: trash });
  randomNavigationController(control, position);
  searchNavigationController(control, position);
};
