import { navigationHandler } from "./navigationHandler";

import {
  createSimulation,
  Position,
  Control,
  Container,
  Detector,
  Map,
} from "sea-drone-simulation";
import { testCoordinates } from "./testData";

//create && start simulation
const simulation = createSimulation({
  element: document.getElementById("pool"),
  loop: (value: {
    position: Position;
    control: Control;
    container: Container;
    detector: Detector;
    map: Map;
  }) =>
    navigationHandler({
      ...value,
      command: "START",
      coordinates: testCoordinates,
      enableRandom: true,
    }),
  numberOfBottles: 60,
  topLeftGeoPointMap: { longitude: 52.440704, latitude: 13.627919 },
  bottomRightGeoPointMap: { longitude: 52.441704, latitude: 13.626919 },
});
simulation.start();
